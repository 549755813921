.AccountingTransaction {
  .vatCode {
    margin-right: 20px;
  }

  .btn.account {
    cursor: pointer;
    display: block;
    padding: 0.375rem 0;
    margin-top: 0.25rem;

    &.disabled {
      cursor: inherit;
    }
  }

  .btn-link.disabled {
    opacity: 1;

    &:hover {
      text-decoration: none;
    }
  }

  .form-text {
    padding: 0.375rem 0;
  }

  .col-2 {
    margin: auto;
  }

  .isInverted {
    color: red;
  }

  td.vatColumn {
    display: flex;
    .transactionInvertedIcon {
      padding: 0.375rem 0;

      .reductionIndicator {
        color: red;
        margin-left: 5px;
      }
    }
  }

  svg {
    cursor: pointer;
  }

  .AccountingText {
    margin-top: 0;
  }
}
