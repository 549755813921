.AddDossier {
  .newDossierContainer {
    .row {
      margin-left: 0;

      .col {
        position: relative;
        padding: 1em;
        border: 1px solid #d8d8d8;
        margin: 1.5rem 0 0;
      }
    }
  }
}
