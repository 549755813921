html {
  font-size: 14px;
}

html,
body,
#root,
.App,
.Lea {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f7f8fa !important;
}

a {
  color: #333;

  &:hover {
    color: #333;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

strong,
b {
  font-weight: 600;
}

.small,
small {
  font-size: 90%;
}

div.container {
  margin-left: 0;
  padding-left: 0;
  max-width: none;
}

.Select-control {
  border-radius: 0;
}

.Select-input {
  input {
    height: auto;
  }
}

input {
  &:not([id^='react-select-']) {
    height: 38px;
  }

  &[type='checkbox'],
  &[type='radio'] {
    height: inherit;
  }
}

svg {
  vertical-align: middle;
}

.btn.btn-link { 
  text-decoration: none; 
}

.accountNavigation>button {
  margin-inline: 5px;

}


.activeAccount {
  margin-inline: 5px !important;
}

tbody {
  cursor: pointer;
  // background-color: white !important;
}

.DossierList .container {
  background-color: white !important;
}

// tr:hover{
//   background-color:white !important
// }


th::first-letter {
  text-transform: uppercase;

}

.nav-link::first-letter {
  text-transform: uppercase;
}
 
.bg-light {
  background-color: #f7f8fa !important;
}

.form-control {
  cursor: pointer;
}

.form-control:hover {
  background-color: white !important;
}

.col-xl-3 {
  box-shadow: 1px 1px 1px 1px rgb(189, 184, 184);
}

.DossierList .btn.addDossier {
  padding: 0;
}

input[type=checkbox]:checked {

  background-color: blue !important;
}

.DossierList {
  background-color: #f7f8fa !important;
}


.btn:not(:disabled):not(.disabled) {
  vertical-align: middle;
}

.AccountingTransaction .btn.account {
  width: 50px;
}

