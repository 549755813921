.ShortcutHelpModal {
  table {
    width: 100%;

    th,
    td {
      padding: 5px;
    }

    td:first-child {
      width: 75px;
      text-align: right;
    }

    .key {
      background: #f3f3f3;
      padding: 0.25em 0.5em;
      border-radius: 3px;
      font-weight: bold;

      & + .key {
        margin-left: 0.5em;
      }
    }
  }
}
