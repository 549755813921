.ImageContainer {
  position: relative;
  flex-grow: 1;

  .ImageContainerContainer {
    &.loadingOverlay {
      img {
        opacity: 0;
      }
      .loader {
        border: 16px solid #f3f3f3;
        border-top: 16px solid #007bff;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -60px;
        margin-left: -60px;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    img {
      width: 90%;
      max-width: 1200px;
      height: auto;
    }

    .ImageContainerImageList {
      transform-origin: 0 0;

      img {
        width: auto;
        max-width: 100% !important;
        height: 100vh;
        margin: auto;
        display: block;
      }
    }
  }

  &.inFlexbox .ImageContainerContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: scroll;
  }

  .btn.expand {
    position: absolute;
    right: 1em;
    top: 1em;
  }

  .btn.arrow-right {
    position: absolute;
    right: 1em;
    top: 10em;
  }

  .btn.arrow-left {
    position: absolute;
    left: 1em;
    top: 10em;
  }

  &.inFlexbox {
    .btn.expand {
      right: 2em;
    }

    .btn.arrow-right {
      right: 2em;
    }
  }

  &.NoImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
