.BankingTransactionDescription {
  p {
    margin-bottom: 0;

    &.description--cropped {
      height: 80px;
      line-height: 20px;
      overflow: hidden;
    }
  }
}
