.BankingDocumentList {
  .documentDetails {
    margin-top: 1em;
  }

  .col {
    svg {
      margin-top: 9px;
      cursor: pointer;
    }
  }
}
