.AccountingTextAutoSuggest {
  width: 100%;
  max-width: 100%;

  .react-autosuggest__container {
    width: 100%;
    position: relative;
  }

  .react-autosuggest__suggestions-container {
    width: 100%;
  }

  .react-autosuggest__suggestions-container--open {
    overflow: hidden;
    width: 100%;
    top: 100%;
    background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
    position: absolute;
    z-index: 9999;
    box-sizing: border-box;
  }

  .react-autosuggest__suggestions-list {
    padding: 0px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #DEEBFF;
  }

  .react-autosuggest__input:disabled {
    color: hsl(0,0%,60%);
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0,0%,95%);
    border-color: hsl(0,0%,90%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
  }

  .react-autosuggest__suggestion {
    color: inherit;
    padding: 8px 12px;
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
  }

  .react-autosuggest__input {
    width: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 5px;
  }
}

