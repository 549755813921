.TemplateList {
  margin: 20px;

  table {
    table-layout: fixed;

    thead,
    tbody {
      td,
      th {
        &:nth-child(1),
        &:nth-child(7) {
          width: 36px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 12%;
        }

        &:nth-child(2),
        &:nth-child(6) {
          width: 32%;
        }
      }
    }
  }
}
