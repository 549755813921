@import '_variables';

.content-with-sidepanel {
  display: flex;
  flex-flow: row;

  &--contain {
    height: 100%;
    max-height: calc(100% - #{$navigation-height});

    & > :nth-child(1) {
      &.is-closed {
        justify-content: flex-end;
      }
    }
  }

  &__toggle {
    margin-top: 15px;
    text-align: right;
  }

  & > :nth-child(1) {
    display: flex;
    flex-direction: column;
    min-width: 370px;
    max-width: 30%;
    padding: 20px;

    &.is-closed {
      min-width: 0;
      width: auto;
    }
  }

  & > :nth-child(2) {
    margin: 20px 20px 20px 0;
    flex-grow: 1;
    flex-basis: auto;
  }
}

.toggle-wrapper {
  margin-top: 10px;

  label {
    padding: 0.3em;
    font-size: 17px;
    display: block;
  }

  .toggleContainer {
    width: 100%;
    position: relative;
    background: #ffffff;
    border: 1px solid #eeeeee;
    z-index: 0;

    .toggleOption {
      display: inline-block;
      position: relative;
      z-index: 1;
      text-align: center;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      color: #807b77;
      font-size: 1em;
    }

    .toggleOption.selected {
      color: #ffffff;
      cursor: initial;
    }

    .toggle {
      position: absolute;
      height: 100%;
      bottom: 0;
      left: 0;
      transition: all 0.4s ease-in-out;
      background: #808080;
    }
  }
}
