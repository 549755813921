.BankingTransactionList {
  position: relative;
  height: 100%;

  margin-top: 15px;

  border: 2px dotted rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;

  form {
    .badge {
      cursor: pointer;
    }
  }

  table.table {
    border: 1px solid #dee2e6;
    padding: 10px;
    font-size: 0.9rem;

    tr {
      border: 1px solid rgba(0, 0, 0, 0);

      &.title {
        cursor: inherit;
        height: 54px;

        &:hover {
          background: inherit;
          border: none;
        }
      }

      th {
        border-top: none;
        border-bottom-width: 1px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 1rem;
      }

      td {
        padding-left: 5px;
        padding-right: 15px;
      }
    }

    &.transactionHeader {
      padding-right: 15px;
      margin-bottom: 0;

      td {
        vertical-align: middle;
      }
    }
  }

  .transactionBody {
    overflow: scroll;
    height: 100%;
  }

  .selectionInfo {
    display: flex;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }

    .btn {
      padding: 0 0 0 1em;
    }
  }
}
