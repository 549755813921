.CSVExport {
  margin: 20px;
  Button {
    margin-right: 20px;
  }

  .ClarificationList {
    display: inline-block;
  }

  .Error{
    color: red;
  }
}

.ClarificationConfirmModal{
  .modal-body {
    max-height: 500px;
    overflow-y: scroll;
  }
}
