.ExportStep {
  .stepContainer {
  padding: 20px;
  }

  .DateInput {
    display: inline-block;
    width: 200px;
    margin-left: 20px;
  }

  .toggle-wrapper {
    display: inline-block;
  }
}
