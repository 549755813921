.Tooltip {
  display: inline-block;

  & [disabled] {
    pointer-events: none;
  }

}

.TooltipWindow.imageFile {
  &.tooltip {
    filter: none;
    opacity: 1;
    background: transparent;

    .tooltip-inner {
      background: transparent;
      max-width: 580px;
    }


    .image {
      width: 580px;
      height: 580px;

      img {
        border: solid 1px black;
        margin: auto;
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
