.CompanyOverviewRow {
  margin-left: 0;

  &.mergeAllowed {
    cursor: pointer;
  }

  .companyName:hover {
    text-decoration: underline;
  }

  .col-7, .col-3, .col-2, .col-1 {
    padding: .75rem 0;
    border-top: 1px solid #dee2e6;
    margin-left: 0;

    &.arrow {
      svg {
        width: 35px;
        height: 35px;
        transform: rotate(90deg);
        cursor: pointer;
      }
    }
  }

  span {

    &:empty:before {
      content: '\200b'; // unicode zero width space character
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    white-space: nowrap;
  }
}
