.CurrencyInput {
  input {
    text-align: right;
  }

  &.invalidValue {
    input {
      background-color: #fd8585;
    }
  }
}
