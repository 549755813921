.DocumentList {
  position: relative;
  border: 2px dotted rgba(0, 0, 0, .1);
  margin-top: 15px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: column;

  .container.headerBar {
    margin-left: 0;
    padding-left: 0;
    max-width: none;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    flex-shrink: 1;
    flex-grow: 0;

    .row {
      .navigation {
        margin-left: 15px;
      }

      .col-auto.right {
        margin-left: auto;

        .errorMessage {
          color: red;
          margin-right: 10px;
        }

        .lockIcon {
          color: #666;
          cursor: pointer;
        }
      }

      button {
        margin-left: 10px;
      }
    }
  }

  > .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #007bff;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -60px;
  }
}
