.RuleEdit {
  .editMask {
    background-color: #fff;
    position: relative;
    .formContainer {
      .row:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .btn-orange {
    color: #212529;
    background-color: #fb8e1e;
    border-color: #fb8e1e;
  }
}
