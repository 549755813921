.DocumentCount {
  width: 500px;
  table {
    tr {
      td {
        &:first-of-type {
          padding-left: 0;
        }
        &:nth-of-type(2), &:nth-of-type(4) {
          text-align: right;
          padding-right: 0;
        }
      }
    }
  }
}
