.FilterBar {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;

  .container {
    margin-left: 0;
  }
}

$select-menu-max-height: 300px;
