.editMask{
  .badge {
    cursor: pointer;
  }

  .propertiesEdit {
    margin-top: 20px;
    &> div, &> input {
      width: 200px;
      display: inline-block;
      margin-right: 20px;
    }
  }
}

.GlobalMutation {
  margin-bottom: 20px;
}

