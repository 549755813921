.OverviewRuleEdit {
  td {
    &.companyInput Input {
      width: 100%;
    }
    svg {
      cursor: pointer;
    }
  }
}
