.CustomerChoices {
  .iconContainer {
    width: 60px;
    .approveIcon {
      cursor: pointer;
      margin-right: 5px;
      &:hover {
        color: green;
      }
    }
    .rejectIcon {
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
  .download-icon {
    cursor: pointer;
  }
}
