.BankingTransactionListSpacer {
  opacity: 0;
  background-color: #e9ecef;
  width: 100%;
  max-width: 800px;
  text-align: center;
  height: 3em;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.dragOver {
    opacity: 0.5;
    background-color: #e9ecef;
  }

  &:not(.disabled):hover {
    opacity: 0.5;
  }
  svg {
    margin-top: 1em;
  }
}

.emptySpacer {
  height: 3em;
}
