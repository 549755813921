.Navigation {
  position: relative;

  .dossierProps {
    margin-left: 50px;

    .form-group {
      display: inline-block;
      margin-bottom: 0;
    }

    &.is-archived {
      color: #a0f;

      select:not(:focus) {
        color: inherit;
      }
    }
  }

  .dropdown-item {
    padding: 0.5rem 1.5rem;
  }

  .nav-item {
    align-items: center;
    display: flex;
  }
}
