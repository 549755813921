.OverviewAccountEdit {
  .badgeContainer {
    vertical-align: middle;
    white-space: nowrap;

    .badge {
      margin-right: 2px;
      cursor: pointer;
    }
  }

  td.bank {
    min-width: 250px;
  }

  td.toggle {
    min-width: 200px;
  }
}

