.AccountOverview {
  max-width: 1000px;
  padding: 20px;

  h1 {
    padding: .375rem .75rem;
  }

  .title th {
    border-top: none;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 1.2em;
  }

  .errorMessage {
    color: red;
    margin-left: 10px;
  }
}
