.DocumentOverview {
  tr, td {
    cursor: pointer;
  }

  td .form-text {
    margin-top: 0;
  }

  .transactionHeader td{
    border-top: 2px solid #dee2e6;
    padding-top: 2.2rem;
  }

  .accTransHeader td{
  }

  .accTransHeader .noBorder, .documentHeader .noBorder  {
    border-top: none;
    border-top-style: none;
  }

  .empty td {
    height: 0.75rem;
    border: none;
  }

  .red {
    color: red;
  }


  .toggle-wrapper {
    width: 500px;

    label {
      padding: 0.3em;
      font-size: 17px;
      display: block;
    }

    .toggleContainer {
      width: 100%;
      position: relative;
      background: #ffffff;
      border: 1px solid #eeeeee;

      .toggleOption {
        display: inline-block;
        position: relative;
        z-index: 1;
        text-align: center;
        height: 36px;
        line-height: 36px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        color: #807b77;
        font-size: 1em;
      }
      .toggleOption.selected {
        color: #ffffff;
        cursor: initial;
      }
      .toggle {
        position: absolute;
        height: 100%;
        bottom: 0;
        left: 0;
        transition: all 0.4s ease-in-out;
        background: #808080;
      }
    }
  }
}
