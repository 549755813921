.companyInput {
  span.uid {
    white-space: nowrap;

    &:empty:before {
      content: '\200b'; // unicode zero width space character
    }
  }

  ul.uids {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      white-space: nowrap;
    }
  }

  .AccountEdit {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
}
