.DocumentWindow {
  .editIcon {
    margin: 1rem 0 0 1rem;
  }

  .DocumentInformation {
    margin: auto;
  }

  .ImageContainerContainer img {
    display: block;
    margin: auto;
  }
}
