
.ControlStep {
  .tableBody tr {
    cursor: pointer;
  }

  .accountSelect {
    width: 200px;
    margin: 20px;
  }

  .accountNavigation {
    padding: 10px;

    .activeAccount {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
