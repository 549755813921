.DossierList {
  display: inline-block;
  padding: 20px;
  width: 100%;
  h1 {
    margin-bottom: 1em;
  }
  h2 {
    font-size: 27px;
    font-weight: bold;
  }

  .table thead th {
    vertical-align: top;
    white-space: nowrap;
  }

  .table {
    th,
    td {
      white-space: nowrap;
    }

    th:nth-child(1),
    td:nth-child(1) {
      white-space: normal;
    }
  }

  .btn.addDossier {
    padding: 0 5px;
  }

  .row {
    &.header {
      font-weight: bold;
    }
  }

  .newDossierContainer {
    .row {
      margin-left: 0;
      .col {
        position: relative;
        padding: 1em;
        border: 1px solid #d8d8d8;
        margin: 1.5rem 0 0;
      }
    }
  }

  .container {
    margin-top: 1em;
  }

  .badge {
    font-size: 100%;
    margin-right: 0.5rem;

    &-state-active {
      background: red;
      color: #fff;
    }

    &-state-progress {
      background: orange;
      color: #000;
    }

    &-state-clarification {
      background: blue;
      color: #fff;
    }

    &-state-finished {
      background: green;
      color: #fff;
    }
  }

  .toggle-wrapper {
    max-width: 400px;
  }
}

.btn-link {
  color: #08489b;
}

.input_search {
  cursor: pointer !important;
  border: none;
  width: 500px !important;
}

.input_search:hover {
  background-color: transparent !important;
}

body {
  background-color: white !important;
}

.col-xl-3 {
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 0.5rem;
}

.form-check {
  height: 30px !important;
}

.btn-danger {
  border-color: #08489b !important;
  background-color: #08489b !important;
}

.btn-danger:hover {
  background-color: #08489b !important;
}
td::first-letter {
  text-transform: uppercase;
}

.UploadDropzone .dropzone .content {
  background-color: white !important;
  box-shadow: 3px 3px 3px 3px rgb(189, 184, 184);
  border-radius: 0.3rem;
}

.UploadStep .stepContainer .UploadDropzone.second {
  margin-top: 31px !important;
}
.navbar-light .navbar-brand {
  color: #08489b !important;
  font-weight: bold;
}
.DossierList h2 {
  font-weight: bold;
}

.DossierList .badge-state-clarification {
  background-color: #08489b;
}

.toggle-wrapper .toggleContainer .toggleOption.selected {
  background-color: #08489b;
}
.search-box {
  display: flex;
  border: 5px solid#f7f8fa;
  background-color: white !important;
  border-radius: 2px;
  max-width: 350px;
  padding: 5px;
}
.input_searchform-control {
  border: none;
  outline: none;
}
.input_searchform-control:focus {
  border: none !important;
  outline: none !important;
}
.search-icon {
  display: flex;
  justify-content: start;
  margin: 10px 10px 0 5px;
  font-size: large;
}

h1 {
  font-weight: bold !important;
}
.btn-warning {
  color: white;
  background-color: #08489b !important;
  border-color: #08489b !important;
}

.btn-warning:hover {
  background-color: #08489b;
  border-color: #08489b;
  color: white;
}

.btn-primary {
  background-color: #08489b;
  border-color: #08489b;
  color: white;
}

.btn-primary:hover {
  background-color: #08489b;
  border-color: #08489b;
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #08489b;
}

.list-group-item.active {
  background-color: #08489b;
  border-color: #08489b;
  color: white;
}
.btn:not(:disabled):not(.disabled) {
  background-color: #08489b;
  border-color: #08489b;
  color: white;
}
.BankingDocumentEdit {
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
  padding-bottom: 750px;
  border: #08489b !important;
  outline: none !important;
}

h2 {
  font-weight: bold;
}

input[type='checkbox']:checked {
  background-color: blue !important;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #08489b !important;
  background-color: #e1f7fc !important;
}
.form-control:disabled {
  background-color: white !important;
}
.form-control:active {
  box-shadow: none;
  outline: none;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}
.UploadStep .stepContainer {
  background-color: #f7f8fa !important;
}

.content-with-sidepanel {
  background-color: #f7f8fa !important;
}

.Statistic {
  background-color: #f7f8fa !important;
}
.UserManual {
  background-color: #f7f8fa !important ;
}

.Lea {
  background-color: #f7f8fa;
}

.dropdown-item:active {
  background-color: #08489b !important;
}
.dropdown-item.active {
  background-color: #08489b;
}

.dropdown-item:focus {
  background-color: #08489b !important;
}

.input:disabled {
  background-color: #08489b !important;
  color: #08489b !important;
}

.DocumentList {
  border: none !important;
}
.BankingTransactionList {
  border: none !important;
}


.form-control:focus {
  border-color: #08489b !important;
}

.toggle-wrapper .toggleContainer .toggleOption {
  border-radius: 10px !important;
}

.toggleContainer {
  border-radius: 10px !important;
}

.div.toggle {
  background: #08489b !important;
}
.toggle-wrapper .toggleContainer .toggle {
  background: #08489b !important;
  border-radius: 10px !important;
}

.css-6j8wv5-Input {
  background-color: white !important;
}

.AccountingTransaction .btn.account {
  margin-top: 0.25rem !important;
}

// .AccountingTransaction .btn.account {
// margin-top: 20px !important;
// padding-top: 0 !important;
// }

// .AccountingTransaction .form-text {
//   margin-top: 20px !important;
// }

.AccountingTransactionList table th:not(:last-child):not(:empty) {
  background-color: #f7f8fa !important;
}
.AccountingTransactionList table th {
  background-color: #f7f8fa !important;
}

.AccountingTransactionList table td:not(:last-child):not(:empty) {
  background-color: #f7f8fa !important ;
}
.AccountingTransactionList table td {
  background-color: #f7f8fa !important ;
}

.css-6j8wv5-Input {
  background-color: initial !important;
}

.DocumentListNavigation .IndexInput {
  background-color: #f7f8fa !important;
}
.input-group-text {
  background-color: white !important;
}

.BankingDocumentEdit .editAccounts {
  width: 100px;
  margin-bottom: 2px;
}
.BankingDocumentEdit .editBanks {
  width: 80px;
  margin-bottom: 2px;
}

.btn {
//  height: 40px;
//  width: 120px !important;
padding: 3px 5px;
width: fit-content;
}


.BankingTransactionListSpacer {
  opacity: 1 !important;
  background-color: #e9ecef;
  width: 100%;
  max-width: 800px;
  text-align: center;
  height: 3em;
  // position: absolute;
}


.Tooltip:disabled{
  background-color: #08489b !important;

}


.Tooltip:disabled{
  background-color: #08489b !important;
  color: #08489b !important;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #08489b !important;
  border-color: #08489b  !important;
}


.btn-primary.disabled, .btn-primary:disabled {

  color: #fff;
  background-color: #08489b !important;
  border-color: #08489b  !important;
}

.css-26l3qy-menu{
  background-color: none!important;
  color:  none !important;
}

.css-26l3qy-menu::selection{
  background-color: none!important;
  color: none !important;
}



.navbar-light .navbar-nav .nav-link{

display: flex;
justify-content: space-between;
margin-right: 20px;
white-space: nowrap;
}

