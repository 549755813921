.PeriodEdit {
  input {
    width: 100px;
    display: inline-block;
    vertical-align: top;
  }
  .until {
    display: inline-block;
    vertical-align: top;
    margin: 8px 10px;
  }
  .periodEndButton {
    margin-left: 20px;
  }
  .errorMessage {
    color: red;
    margin-right: 10px;
  }
  .Select {
    display: inline-block;
    width: 96px;
  }
}
