.ListExportSelection {
  .editMask {
    .buttonBar {
      padding: 20px 0;

      .AccountEdit {
        margin-right: 20px;
        width: 120px;
        display: inline-block;
        vertical-align: middle;
        height: 34px;
      }
      button {
        margin-right: 20px;

        &.btn-orange {
          color: #212529;
          background-color: #fb8e1e;
          border-color: #fb8e1e;
        }
      }
    }
  }
}
