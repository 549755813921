.BankingDocumentEdit {
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
  border: 2px dotted rgba(0, 0, 0, 0.1);

  // This compensates for the 750 height of AutoSizer/List for <Transactions>.
  // The virtual scrolling forces a height of 0px otherwise.
  // https://github.com/bvaughn/react-virtualized/blob/master/docs/usingAutoSizer.md#why-is-my-autosizer-setting-a-height-of-0
  padding-bottom: 750px;

  h2 {
    span.badge {
      font-size: 0.75rem;
    }
  }

  .container {
    .row {
      &.buttons {
        margin: 1em 0;
        .col {
          padding-left: 0;

          .badge {
            cursor: pointer;
          }
          button {
            margin-left: 1em;
          }
        }
      }
    }
  }

  .errorMessage {
    color: red;
    margin-left: 10px;
  }

  .toggle-wrapper {
    label {
      padding: 0.3em;
      font-size: 17px;
      display: block;
    }

    .toggleContainer {
      max-width: 200px;
      position: relative;
      background: #ffffff;
      border: 1px solid #eeeeee;

      .toggleOption {
        display: inline-block;
        position: relative;
        z-index: 1;
        text-align: center;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        color: #807b77;
        font-size: 1em;
      }
      .toggleOption.selected {
        color: #ffffff;
        cursor: initial;
      }
      .toggle {
        position: absolute;
        height: 100%;
        bottom: 0;
        left: 0;
        transition: all 0.4s ease-in-out;
        background: #808080;
      }
    }
  }

  .editAccounts,
  .editBanks {
    padding: 0;
  }

  .Transactions {
    .loader {
      display: none;
      border: 16px solid #f3f3f3;
      border-top: 16px solid #007bff;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
      position: fixed;
      z-index: 200;
      left: 60%;
      top: 50%;
    }
    &.loading {
      .loader {
        display: block;
      }
      opacity: 0.5;
    }
  }

  .startingBalance {
    display: block;

    span {
      display: block;
      float: right;
    }

    button {
      padding: 0;
      margin: 0 0 0 0.5em;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
