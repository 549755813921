svg.DocumentStateIndicator {
  margin-left: 5px;

  &.green {
    color: green;
    fill: green;
  }

  &.red {
    color: red;
    fill: red;
  }

  &.blue {
    color: #17a2b8;
    fill: #17a2b8;
  }

  &.yellow {
    color: #ffb029;
    fill: #ffb029;
  }

  &.blue {
    color: #007bff;
    fill: #007bff;
  }
}
