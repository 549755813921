.EditStep {
  &.periodStart {
    padding: 50px;
    Button {
      margin-top: 20px;
    }
  }
  .periodInfoText {
    display: block;
    margin: 20px 0;
  }
}
