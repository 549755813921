.BasicDocumentInformation {
  legend {
    font-size: inherit;
  }

  .btn-link {
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    vertical-align: baseline;
  }

  .AccountingTextWrapper {
    display: flex;
    margin-top: 10px;

    .AccountingText {
      flex: 1;
      margin-top: 0;
    }

    button {
      width: 100%;
    }

    .individualAccountingTexts {
      cursor: pointer;
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }

  .ClarificationText {
    margin-top: 10px;
  }

  .totalAmountInput {
    & > .CurrencyInput:first-child {
      margin-bottom: 10px;
    }

    .input-group {
      max-width: 300px;
    }
  }

  .totalAmountCHF {
    font-size: 100%;
    margin-left: 0.6rem;
    margin-top: 20px;
  }

  .totalAmountCHFOverride {
    margin-top: 10px;
  }

  .AccountEdit {
    margin-bottom: 10px;
  }

  table {
    width: 100%;
  }

  span.select {
    cursor: pointer;
  }

  .badge {
    margin: 0 0.75rem;
  }

  .col-5 {
    padding-right: 0;
  }

  .toggle-wrapper {
    margin-top: 10px;

    label {
      padding: 0.3em;
      font-size: 17px;
      display: block;
    }

    .toggleContainer {
      width: 100%;
      position: relative;
      background: #ffffff;
      border: 1px solid #eeeeee;

      .toggleOption {
        display: inline-block;
        position: relative;
        z-index: 1;
        text-align: center;
        height: 36px;
        line-height: 36px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        color: #807b77;
        font-size: 1em;
      }

      .toggleOption.selected {
        color: #ffffff;
        cursor: initial;
      }

      .toggle {
        position: absolute;
        height: 100%;
        bottom: 0;
        left: 0;
        transition: all 0.4s ease-in-out;
        background: #808080;
      }
    }
  }

  .auxButtons {
    float: right;

    svg {
      margin: 0 5px;
    }
  }

  .duplicate-icon,
  .download-icon {
    cursor: pointer;
  }

  .duplicate-icon {
    color: red;
    fill: red;
  }
}
