.AccountingTransactionList {
  table {
    width: 100%;

    th {
      font-weight: normal;
    }

    th,
    td {
      padding-bottom: 10px;

      &:not(:last-child):not(:empty) {
        padding-right: 1rem;
      }
    }
  }
}
