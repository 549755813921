.RuleOverview {
  max-width: 500px;
  padding: 20px;
  .title th {
    border-top: none;
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 1.2em;
  }

  .companyNameColumn {
    width: 70%;
  }
}
