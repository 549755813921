.Statistic {
  padding: 20px;

  .total {
    font-weight: bold;
  }

  .generateButtons button {
    margin-left: 20px;
  }

  table {
    max-width: 500px;

    tr {
      td {
        white-space: nowrap;

        h2 {
          margin-top: 1em;
        }

        &:nth-of-type(2){
          text-align: right;
        }
      }
    }
  }
}
