.UploadDropzone {
  .dropzone {
    &:focus {
      outline: 0;
    }

    &.dropzone--isActive {
      .content {
        border-color: green;
        background: #f8f9fa;
        border-style: solid;
      }
    }

    .content {
      line-height: 196px;
      text-align: center;
      border-width: 2px;
      border-color: rgb(102, 102, 102);
      border-style: dashed;
      border-radius: 5px;
      width: auto;
      height: 200px;
      white-space: nowrap;

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        word-break: break-all;
      }
    }
  }

  padding: 1em 0;
}
