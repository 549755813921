.PostEditStep {
  margin: 20px;
  overflow: auto;

  .body {
    display: flex;
    flex-flow: row;

    .tileList,
    .tileDivider {
      margin-right: 4em;
    }

    .tileDivider {
      button {
        margin-top: 10px;
      }
    }

    .filter {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1em;
    }

    .tile {
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 400px;
      min-height: 150px;
      padding: 10px;
      margin: 20px 100px 50px 0;

      &.selected {
        border: 1px solid #20c997;
        margin-right: 0;
        margin-left: 100px;
      }

      &.document {
        margin-left: 0;
        button.btn-link {
          padding: 0;
        }
      }

      &.transaction {
        .meta {
          color: #6c757d;
          .form-text {
            display: initial;
          }
          span {
            margin-left: 1em;
          }
        }
      }
    }
  }
}
