.BankingTransaction {
  display: flex;
  height: 100%;
  border-top: 1px solid #dee2e6;

  &.clickable {
    cursor: pointer;
  }

  &.selected {
    background: #ffc;
  }

  .wrapper {
    display: flex;
    width: calc(100% - 15px);


    > div {
      padding: .75rem 5px .75rem 5px;
      font-size: .9rem;

      &.details {
        width: 24%;
      }

      &.icons {
        width: 9%;
      }
    }

    div {
      vertical-align: top;

      &.description {

        p {
          margin-bottom: 0;

          &.description--cropped {
            height: 40px;
            line-height: 20px;
            overflow: hidden;
          }
        }

        button.more {
          padding: 0;
        }
      }

      &.description {
        flex-grow: 1;
      }

      p {
        margin-bottom: 0;
      }

      .actionIcon {
        display: block;
        cursor: pointer;
        color: #666;
      }

      .actionIcon:first-child {
        margin-bottom: 5px;
      }

      .actionIcon_disabled {
        color: #66666665;
      }

      .currency {
        .small {
          font-size: 100%;
        }
      }
    }
  }
}
