div.container.CompanyOverview {
  padding: 20px;

  .title th {
    border-top: none;
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 1.2em;
  }

  .companyNameColumn {
    width: 100%;
    margin-left: 0;

    > div {
      padding: .75rem 0;
      border-top: 1px solid #dee2e6;
      font-weight: bold;
    }
  }

  .mergeBox {
    border: 1px solid #dee2e6;
    padding: 20px;
    position: fixed;
    background: white;

    .companiesToMerge {
      padding: 2em 0;
    }

    Button {
      margin-right: 20px;
      display: block;
    }

    .correctCompany {
      padding: 1em 0;

      .correctCompanyName {
        padding: .375rem .75rem;
      }
    }
  }

  .topOnly {
    margin-bottom: 20px;
    span {
      margin-left: 20px;
    }
  }
}
