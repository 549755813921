.modalbg{
    position: fixed;
    min-width: 100%;
    height: 100%;
    z-index: 999999;
    top: 0;
   left: 0;
    background-color: rgba(0, 0, 0, 0.438);
  }
  .modals{
    position: relative;
    top: 5%;
    left: 50%;
    transform: translate(-50%,-5%);
    max-width: 500px;
    width: 100%;
    height: 300px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
  }
  .modal__top{
      height: 150px;
  }
  .modal__middle{
    flex: 1 1 100%;
    padding: 0px 15px;
    p{
      color: red;
    }
    h3{
      // font-size: 20px;
       margin-bottom: 25px;
       text-align: center;
       color: gray;
      font-weight: 300;
      font-size: 1.25rem;
    }
  }
  .modal__bottom{
    display: flex;
    justify-content: flex-end;
    padding: 0px 25px 15px 0px;
    .modal_button{
        cursor: pointer;
        border: 1px solid rgb(126, 122, 122);
        padding: 5px 10px;
        color: gray;
        border-radius: 2px;
        font-size: 1rem;
        font-weight: 400;
    }
    .modal_okbutton{
      width: 80px;
      margin-right: 10px;
      background-color: #28a745;
      cursor: pointer;
      border:none;
      padding: 5px 10px;
      color: #fff;
      border-radius: 2px;
      font-size: 1rem;
      font-weight: 400;
    }
    button:disabled{
      background-color: #28a74679;
      border:none

    }
  }



#file,#file_error {
  width: 100%;
  height: 30px;
  background: #DFDFE7;
  border-radius: 12px;
}

#file::-webkit-progress-bar,#file_error::-webkit-progress-bar {
  width: 100%;
  height: 30px;
  background: #DFDFE7;
  border-radius: 12px;
}
#file::-webkit-progress-value {
  background-color: rgb(78, 140, 232);
  border-radius: 12px;
}
#file_error::-webkit-progress-value{
  background-color:red;
  border-radius: 12px;
}
