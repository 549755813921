.BankingTransactionEdit {
  width: 100%;
  cursor: grab;

  &.locked {
    cursor: auto;
  }

  svg {
    cursor: pointer;
  }

  .smallCol {
    padding: 0;
    flex: 0 0 0.5%;
  }

  .row {
    margin-left: 0;
  }

  .col-auto {
    padding-left: 5px;
    padding-right: 5px;
  }

  .continuousBalance {
    text-align: right;
    display: block;
    width: 100%;
    padding-right: 12px;
  }

  .row.childRow {
    margin-top: 1em;
  }
}
