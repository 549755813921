.DocumentListNavigation {
  color: #666;

  span {
    margin: 0 10px;
    color: #333;
  }

  svg {
    &.clickable {
      cursor: pointer;
    }

    &.invisible {
      visibility: hidden;
    }
  }

  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5em;
  }

  .IndexInput {
    box-sizing: padding-box;
    width: auto;
    min-width: 1ch;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    outline: none;
    font: inherit;
    color: inherit;
    text-align: right;
    cursor: pointer;
  }
}
